<template>
    <div class="address">
      <div style="display: flex;justify-content: space-between;">
        <h3>地址管理</h3>
        <a-button v-if="list.length > 0" type="primary" @click="addAddress()" style="background-color: #d90d0d;">添加</a-button>
        <a-drawer
                title="请填写收货信息"
                :width="720"
                :visible="visible"
                :body-style="{ paddingBottom: '80px' }"
            >
                <a-form :model="form" :rules="rules" layout="vertical" >
                    <a-form-item label="收货人" required>
                        <a-input v-model:value="form.realName" placeholder="请输入收货人"/>
                    </a-form-item>
                    <a-form-item label="手机号" required>
                        <a-input v-model:value="form.phone" placeholder="请输入手机号"/>
                    </a-form-item>
                    <a-form-item label="地区" required>
                        <a-space>
                            <a-select
                            v-model:value="form.province"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey"
                            >
                                <a-select-option v-for="(item, key) in provinceData" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.city"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey2"
                            >
                                <a-select-option v-for="(item, key) in cities" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.district"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey3"
                            >
                                <a-select-option v-for="(item, key) in districtData" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.street"
                            style="width: 120px"
                            placeholder="请选择"
                            >
                                <a-select-option v-for="(item, key) in streetData" :key="key" :value="item"></a-select-option>
                            </a-select>
                        </a-space>
                                            </a-form-item>
                    <a-form-item label="详细地址" required>
                        <a-textarea v-model:value="form.detail" placeholder="请输入详细地址"/>
                    </a-form-item>
                </a-form>
                <div
                :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }"
                >
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="onSubmit" style="background-color: #d90d0d;">提交</a-button>
                </div>
            </a-drawer>

      </div>
       <div v-if="list.length > 0">
            <div v-for="item in list" :key="item.id" class="listbox">
                <div class="top">
                    <span style="margin-right: 20px;">{{ item.realName }}</span>
                    <span>{{ item.phone }}</span>
                </div>
                <div class="bottom">
                    <span>{{ item.province }}{{ item.city }}{{ item.district }}{{ item.detail }}</span>
                    <span @click="showModal()">修改</span>
                </div>
            </div>
       </div>
       <div v-else>
          <a-divider class="color">请先添加收货地址</a-divider>
          <a-button type="primary" class="btn1" @click="addAddress()"><PlusOutlined />添加地址</a-button>
          <!-- 抽屉 -->
            <a-drawer
                title="请填写收货信息"
                :width="720"
                :visible="visible"
                :body-style="{ paddingBottom: '80px' }"
            >
            <a-form :model="form" :rules="rules" layout="vertical" >
                    <a-form-item label="收货人" required>
                        <a-input v-model:value="form.realName" placeholder="请输入收货人"/>
                    </a-form-item>
                    <a-form-item label="手机号" required>
                        <a-input v-model:value="form.phone" placeholder="请输入手机号"/>
                    </a-form-item>
                    <a-form-item label="地区" required>
                        <a-space>
                            <a-select
                            v-model:value="form.province"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey"
                            >
                                <a-select-option v-for="(item, key) in provinceData" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.city"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey2"
                            >
                                <a-select-option v-for="(item, key) in cities" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.district"
                            style="width: 120px"
                            placeholder="请选择"
                            @change="selectKey3"
                            >
                                <a-select-option v-for="(item, key) in districtData" :key="key" :value="item"></a-select-option>
                            </a-select>
                            <a-select
                            v-model:value="form.street"
                            style="width: 120px"
                            placeholder="请选择"
                            >
                                <a-select-option v-for="(item, key) in streetData" :key="key" :value="item"></a-select-option>
                            </a-select>
                        </a-space>
                                            </a-form-item>
                    <a-form-item label="详细地址" required>
                        <a-textarea v-model:value="form.detail" placeholder="请输入详细地址"/>
                    </a-form-item>
                </a-form>
                <div
                :style="{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1,
                }"
                >
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="onSubmit" style="background-color: #d90d0d;">提交</a-button>
                </div>
            </a-drawer>

       </div>
    </div>
</template>

<script setup>
import { getAddressList } from "@/api/order";
import XtxCity from "@/components/library/xtx-city.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import { pcaa } from 'area-data-vue'
import Message from "@/components/library/Message";
import {addAddressApi} from '../../api/address'
console.log('pcaa',pcaa);
    //添加地址
    const form = reactive({
        realName:'',
        phone:'',
        province:'',//省
        provinceId:'',
        city:'',//市
        cityId:'',
        district:'',//区
        districtId:'',
        street:'',//街道
        detail:'',
        id:0,
        isDefault:false
    })
    const visible = ref(false)
    const addAddress = ()=>{
        visible.value = true
    }

    const onClose = ()=>{
        visible.value = false
    }

    const onSubmit = async()=>{
        console.log(form);
        // visible.value = false
        if(form.realName == ''){
           return Message({text:'收货人不能为空'})
        }else if(form.phone == ''){
           return Message({text:'手机号不能为空'})
        }else if(form.province == ''){
           return Message({text:'省份不能为空'})
        }else if(form.city == ''){
           return Message({text:'城市不能为空'})
        }else if(form.district == ''){
           return Message({text:'地区不能为空'})
        }else if(form.street == ''){
           return Message({text:'街道不能为空'})
        }else{
            let res = await addAddressApi(form)
            if(res.code==200){
                console.log(res);
                visible.value = false
                getlist()
            }
        }
    }

    const options = ref([])
    const provinceData = ref(pcaa['86'])
    const cities = ref([])
    const districtData = ref([])
    const streetData = ref([])
    const getCity = ()=>{
        
    }
    const selectKey = (e) => {
        console.log('province--->',e);
        if (e) {
            Object.keys(provinceData.value).filter(item => {
                if (provinceData.value[item] === e) {
                    cities.value = pcaa[item]
                    console.log('provinceId--->',item);
                    form.provinceId = item
                }
            })
            // ruleForm.value.CITY = undefined;
        }
    }
    const selectKey2 = (e) => {
        console.log('city--->',e);
        if (e) {
            Object.keys(cities.value).filter(item => {
                if (cities.value[item] === e) {
                    districtData.value = pcaa[item]
                    console.log('cityId--->',item);
                    form.cityId = item
                }
            })
            // ruleForm.value.CITY = undefined;
        }
    }
    const selectKey3 = (e) => {
        console.log('district--->',e);
        if (e) {
            Object.keys(districtData.value).filter(item => {
                if (districtData.value[item] === e) {
                    streetData.value = pcaa[item]
                    console.log('districtId--->',item);
                    form.districtId = item
                }
            })
            // ruleForm.value.CITY = undefined;
        }
    }

    const showModal = ()=>{

    }

    const list = ref([])
    const getlist = async()=>{
       let res = await getAddressList()
       if(res.code==200){
        console.log('addresslist--->',res.data);
        list.value = res.data
       }
    }

    onMounted(() => {
        getlist()
        getCity()
    })
</script>

<style lang="less" scoped>
.address{
    width: 100%;
    height: 500px;
    background-color: #fff;
    padding: 10px;
    h3{
        color: #d90d0d;
        display: inline-block;
        margin-top: 5px;
    }
    .color{
        color: grey;
        margin-top: 50px;
    }
    .btn1{
        background-color: #d90d0d;
        color: #fff;
        margin-top: 50px;
        margin-left: 50%;
        transform: translate(-50%);
    }
    div{
        .listbox{
            background-color: #e9e7e7;
            height: 100px;
            padding:0 5px;
            margin-top: 20px;
            .top{
                // padding: 5px;
                height: 40px;
                line-height: 40px;
               
            }
            .bottom{
                background-color: #fff;
                height: 55px;
                line-height: 55px;
                display: flex;
                justify-content: space-between;
                padding:0 5px;
                span{
                    display: inline-block;
                    &:hover{
                        color: #d90d0d;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>